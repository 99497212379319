<template>
	<section
		class="fullWidthBaner"
		:style="`background-image: url(${path(compdata.image)});`"
	></section>
</template>

<script>
export default {
	props: ["compdata"],
	methods: {
	},
	mounted () {
		
	},
};
</script>

<style lang="scss">
.fullWidthBaner {
	height: 435px;
	background-position: center;
	background-size: cover;
	@media (max-width: 1365px) {
		height: 420px;
	}
	@media (max-width: 1023px) {
		height: 400px;
	}
	@media (max-width: 575px) {
		height: 230px;
	}
}
</style>