<template>
	<div class="supportBtn" @click="onClick">
		<transition-group name="supportanim" mode="out-in">
			<span
				class="ic-hand-1"
				v-if="currentItem == 0 && !opened"
				key="k1"
			></span>
			<span
				class="ic-telegram"
				v-if="currentItem == 1 && !opened"
				key="k2"
			></span>
			<!-- <span
				class="ic-twitter"
				v-if="currentItem == 2 && !opened"
				key="k3"
			></span> -->
			<span class="ic-viber" v-if="currentItem == 2 && !opened" key="k4"></span>
			<span class="ic-close-rounded" v-if="opened" key="k5"></span>
		</transition-group>
	</div>
</template>

<script>
export default {
	name: "ContactBtn",
	props: ["state"],
	data() {
		return {
			currentItem: 0,
			// opened: false,
		};
	},
	methods: {
		onClick() {
			this.$emit("click");
			// this.opened = !this.opened;
		},
		next() {
			if (this.currentItem == 2) {
				this.currentItem = 0;
			} else {
				this.currentItem++;
			}
		},
	},
	computed: {
		opened() {
			return this.state || false;
		},
	},
	mounted() {
		setInterval(() => {
			this.next();
		}, 1000);
	},
};
</script>

<style lang="scss">
.supportanim-enter-active,
.supportanim-leave-active {
	transition: 0.3s ease;
}
.supportanim-enter {
	transform: translate(calc(-50% + 50px), -50%) !important;
}
.supportanim-leave-to {
	transform: translate(calc(-50% - 50px), -50%) !important;
}
.supportBtn {
	width: 71px;
	height: 71px;
	box-sizing: border-box;
	border-radius: 50%;
	border: 12px solid #e10b17;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 50;
	bottom: 44px;
	right: 44px;
	box-shadow: 0 0 0 0px rgba(225, 11, 23, 0.3);
	animation: supportBtnAnim 1s linear infinite;
	cursor: pointer;
	overflow: hidden;
	@media (max-width: 1023px) {
		width: 60px;
		height: 60px;
		right: 10px;
		bottom: 10px;
		border: none;
		background-color: #e10b17;
		animation: none;
	}
	.ic-hand-1 {
		font-size: 21px;
		color: #e10b17;
		@media (max-width: 1023px) {
			color: #fff;
		}
	}
	[class^="ic-"],
	[class*=" ic-"] {
		@media (max-width: 1023px) {
			color: #fff;
		}
		color: #e10b17;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@keyframes supportBtnAnim {
		from {
			box-shadow: 0 0 0 0px rgba(225, 11, 23, 0.3);
		}
		to {
			box-shadow: 0 0 0 11px rgba(225, 11, 23, 0);
		}
	}
}
</style>