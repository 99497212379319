<template>
	<div class="sideBar">
		<transition-group
			tag="div"
			class="menuBtnsWrap"
			name="fadefast"
			mode="out-in"
		>
			<template v-if="loaded">
				<a
					href="#"
					@click.prevent="$emit('onmenustatechange')"
					class="item menuBtn"
					key="menubtn"
				>
					<div class="ico"><span class="ic-menu-burger"></span></div>
					<span class="text">{{ $t("menu.menu") }}</span>
				</a>
				<router-link
					v-for="(item, index) in menu"
					:key="index"
					:to="{
						path: item.url,
						params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
					}"
					class="item"
				>
					<div class="ico"><span :class="item.icon"></span></div>
					<span class="text">{{ item.name }}</span>
				</router-link>
			</template>
			<div v-else class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
		</transition-group>
		<div class="botSoc">
			<div class="socWrap" v-if="getSocials">
				<template v-for="(item, index) in getSocials">
					<a
						v-if="item.url"
						:key="index"
						:href="item.url"
						target="_blank"
						class="soc"
						>{{ item.type }}</a
					>
				</template>
			</div>
			<div class="lang">
				<!-- <router-link
					@click.native="onLangSwitch"
					:class="{ active: $i18n.locale == 'ru' }"
					:to="{ name: this.$route.name, params: ruLocaleParam }"
					>ру</router-link
				>
				<span> | </span> -->
				<router-link
					@click.native="onLangSwitch"
					:class="{ active: $i18n.locale == 'uk' }"
					:to="{ name: this.$route.name, params: uaLocaleParam }"
					>укр</router-link
				>
				<span> | </span>
				<router-link
					@click.native="onLangSwitch"
					:class="{ active: $i18n.locale == 'en' }"
					:to="{ name: this.$route.name, params: enLocaleParam }"
					>en</router-link
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { Bus } from "@/main.js";
import { rtParams } from "@/utils.js";
export default {
	name: "SideBar",
	data() {
		return {
			menu: null,
			loaded: false,
		};
	},
	created() {
		this.axios
			.post("/api/menu/get-by-ids", { ids: [102], lang: this.$i18n.locale })
			.then((response) => {
				this.menu = response.data.data[102].items;
				this.loaded = true;
			});
	},
	computed: {
		...mapGetters(["getSocials"]),
		...rtParams,
	},
	mounted() {
		setTimeout(() => {
			Bus.$on("langChange", this.loadMenu);
		}, 100);
	},
	methods: {
		onLangSwitch() {
			this.loaded = false;
			this.loadMenu();
			setTimeout(() => {
				Bus.$emit("langChange");
			}, 500);
		},
		loadMenu() {
			this.axios
				.post("/api/menu/get-by-ids", { ids: [102], lang: this.$i18n.locale })
				.then((response) => {
					this.menu = response.data.data[102].items;
					this.loaded = true;
				});
		},
	},
};
</script>

<style lang="scss">
.loadingFiller {
	flex-grow: 1;
}
.sideBar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 192px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	@media (max-width: 1600px) {
		width: 155px;
	}
	@media (max-width: 1365px) {
		display: none;
	}
	.menuBtnsWrap {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		.item {
			// min-height: 140px;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			text-decoration: none;
			flex-grow: 1;
			transition: 0.3s ease;
			.ico {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				background-color: #f4a700;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: 0.3s ease;
				.ic-dots {
					transform: translate(1px, 0);
				}
				.ic-play {
					transform: translate(1px, 0);
				}
			}
			.text {
				margin-top: 16px;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #1a1a1a;
				padding: 0 10px;
				@media (max-width: 1600px) {
					font-size: 13px;
				}
			}
			&:hover {
				background: #f4a700;
				.ico {
					background-color: #fff;
					transform: scale(0.9);
				}
				.text {
					color: #000;
				}
			}
		}
		.item.menuBtn {
			.ico {
				background-color: #e10b17;
				color: #fff;
			}
			.text {
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #1a1a1a;
				@media (max-width: 1600px) {
					font-size: 13px;
				}
			}
			&:hover {
				background: #f4a700;
				.ico {
					background-color: #fff;
					transform: scale(0.9);
					color: #000;
				}
			}
		}
	}

	.botSoc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 105px;
		.socWrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.soc {
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #1a1a1a;
				text-decoration: none;
				margin: 0 5px;
				&:hover {
					color: #e10b17;
				}
				@media (max-width: 1600px) {
					font-size: 12px;
				}
			}
		}
		.lang {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			text-transform: uppercase;
			margin-top: 24px;
			a {
				color: rgba(0, 0, 0, 1);
				text-decoration: none;
				&.active {
					color: rgba(0, 0, 0, 0.4);
					pointer-events: none;
				}
				&:hover {
					color: #e10b17;
					&.active {
						color: rgba(0, 0, 0, 0.4);
					}
				}
			}
		}
	}
}
</style>