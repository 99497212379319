<template>
	<div class="contactWidget" style="">
		<div class="scroller">
			<div class="closeWrp" @click="$emit('close')"><span class="ic-close-rounded"></span></div>
			<div class="imgWrp">
				<img :src="`${require('@/assets/img/widgetImage.svg')}`" alt="" />
			</div>
			<div class="dwrp">
				<a :href="$store.getters.formLnk" class="contactbtn" >
					<span class="ic-hand-1"></span> {{$t('getVisit')}}
				</a>
			</div>
			<div class="dwrp">
				<p class="caption">{{contactForm.title}}</p>
				<p class="sub">{{contactForm.sub}}</p>
			</div>
			<div class="dwrp messagners">
				<a v-for="(item, index) in getMesasngersT" :key="index" :href="item.url" target="_blank" class="item">
					<div class="icon" :class="getClass(item.type)"><span :class="`ic-${item.type}`"></span></div>
					{{getText(item)}}
				</a>
				<a href="#" class="item" @click.prevent="$emit('opentellwidget')">
					<div class="icon phone2"><span class="ic-phone"></span></div>
					{{$t('getCall')}}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: "ContactWidget",
	methods: {
		getClass(c){
			switch (c) {
				case 'telegram':
					return'teleg'
				case 'viber':
					return'viber'
				case 'phone':
					return'phone'
			}
		},
		getText(item){
			switch (item.type) {
				case 'telegram':
					return'Telegram'
				case 'viber':
					return'Viber'
				case 'phone':
					return item.url.replace('tel:', '')
			}
		}
	},
	computed: {
		...mapGetters([
			'getMesasngersT',
			'contactForm',
		])
	},
};
</script>

<style lang="scss">
.contactWidget {
	position: fixed;
	z-index: 55;
	right: 0;
	top: 50%;
	// height: 100vh;
	max-height: 688px;
	transform: translate(0, -50%);
	background: #fff;
	border-bottom-left-radius: 24px;
	max-width: 217px;
	padding: 0 16px;
	overflow-y: auto;
	@media (max-height: 686px) {
		max-height: unset;
		height: 100vh;
	}
	.scroller {
	}
	.closeWrp {
		display: flex;
		justify-content: flex-end;
		padding: 10px 0;
		.ic-close-rounded {
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 10px;
			cursor: pointer;
		}
	}
	.imgWrp {
		display: flex;
		justify-content: center;
	}
	.dwrp {

	}
	.contactbtn{
		text-decoration: none;
	}
	.contactbtn {
		background-color: #e10b17;
		padding: 0 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		color: #ffffff;
		width: 100%;
		height: 48px;
		border: none;
		margin-top: 32px;
		cursor: pointer;
		&:hover {
			background-color: #c4141d;
		}
		.ic-hand-1 {
			font-size: 21px;
			margin-right: 13px;
		}
	}
	.caption {
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		letter-spacing: -0.02em;
		color: #262628;
		margin: 36px 0 0;
	}
	.sub {
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		letter-spacing: -0.02em;
		color: #8e8e93;
		margin: 4px 0 0;
	}
	.messagners {
		display: flex;
		flex-direction: column;
		margin-top: 28px;
		padding-bottom: 25px;
		.item {
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			color: #262628;
			text-decoration: none;
			& + .item {
				margin-top: 15px;
			}
			.icon {
				width: 35px;
				height: 35px;
				border-radius: 50%;
				margin-right: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				[class^="ic-"],
				[class*=" ic-"] {
					color: #fff;
				}
				&.teleg {
					background: #37aee2;
				}
				&.viber {
					background: #7f4da0;
				}
				&.phone {
					background: #f4a700;
				}
				&.phone2 {
					background: #e10b17;
				}
			}
		}
	}
}
</style>