<template>
	<section class="homeVideoAndInfo">
		<div class="twoCol1">
			<div class="l">
				<h2 class="caption">{{ compdata.title }}</h2>
			</div>
			<div class="r">
				<div class="videoWrap" @click="modalVideo = !modalVideo">
					<img
						:src="`${path(compdata.image)}`"
						:alt="path(compdata.image)"
						class="tmp"
					/>
					<div class="playBtn"><span class="ic-play"></span></div>
				</div>
				<div class="item" v-for="(item, index) in compdata.list" :key="index">
					<p class="title">{{ item.title }}</p>
					<p class="txt" v-html="item.text"></p>
					<template v-if="item.url">
						<router-link
							v-if="!isExtUrl(item.url)"
							:to="{ path: item.url }"
							class="more"
							><span class="ic-arrow-short-rigth"></span>{{$t("details")}}</router-link
						>
						<a
							v-if="isExtUrl(item.url)"
							:href="item.url"
							target="_blank"
							class="more"
							><span class="ic-arrow-short-rigth"></span>{{$t("details")}}</a
						>
					</template>
				</div>
			</div>
		</div>
		<transition name="fade">
			<YtScreen
				v-if="modalVideo"
				:videoId="videoId"
				@close="modalVideo = false"
			></YtScreen>
		</transition>
	</section>
</template>

<script>
import yt from "@/components/common/YtModal.vue";
export default {
	name: "HomeVideoAndInfo",
	props: ["compdata"],
	components: {
		YtScreen: yt,
	},
	data() {
		return {
			modalVideo: false,
			videoId: null,
		};
	},
	methods: {
		youtubeParser(url) {
			var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
			var match = url.match(regExp);
			return match && match[7].length == 11 ? match[7] : false;
		},
	},
	mounted() {
		this.videoId = this.youtubeParser(this.compdata.link)
	},
};
</script>

<style lang='scss'>
.homeVideoAndInfo {
	padding: 80px 110px;
	@media (max-width: 1599px) {
		padding: 80px 48px;
	}
	@media (max-width: 1365px) {
		padding: 80px 48px;
	}
	@media (max-width: 1199px) {
		padding: 80px 48px;
	}
	@media (max-width: 1023px) {
		padding: 64px 48px;
	}
	@media (max-width: 575px) {
		padding: 64px 16px;
	}

	background: #f2f2f7;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: #f8f8f8;
		border-top-left-radius: 193px;
		z-index: 1;
		@media (max-width: 1599px) {
			border-top-left-radius: 193px;
		}
		@media (max-width: 1365px) {
			border-top-left-radius: 160px;
		}
		@media (max-width: 1199px) {
			border-top-left-radius: 160px;
		}
		@media (max-width: 1023px) {
			border-radius: 0;
		}
		@media (max-width: 575px) {
			border-radius: 0;
		}
	}

	.twoCol1 {
		position: relative;
		z-index: 5;
		.l {
			.caption {
				font-style: normal;
				font-weight: 600;
				font-size: 71px;
				line-height: 110%;
				color: #f4b147;
				padding-right: 30px;
				position: sticky;
				top: 20px;
				@media (max-width: 1599px) {
					font-size: 65px;
				}
				@media (max-width: 1023px) {
					font-size: 55px;
					margin-left: 60px;
					text-align: left;
					margin-bottom: 0;
					margin-top: 0;
					margin: 0;
					padding: 0;
				}
				@media (max-width: 767px) {
					font-size: 45px;
				}
				@media (max-width: 575px) {
					font-size: 40px;
					text-align: left;
				}
				@media (max-width: 375px) {
					font-size: 30px;
				}
			}
		}
		.r {
			@media (max-width: 1023px) {
				margin-top: 32px;
			}
			.videoWrap {
				margin-bottom: 16px;
				width: 100%;
				position: relative;
				cursor: pointer;
				.tmp {
					width: 100%;
					position: relative;
					z-index: 5;
				}
				.playBtn {
					position: absolute;
					z-index: 10;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 68px;
					height: 68px;
					border-radius: 50%;
					background-color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: 0.3s ease;
					.ic-play {
						font-size: 15px;
					}
				}
				// &:hover{
				//     .playBtn{
				//         transform: translate(-50%, -50%) scale(0.9);
				//         .ic-play{
				//             transform: scale(1.1);
				//         }
				//     }
				// }
			}
			.item {
				margin-top: 16px;
				box-shadow: 0 1px 0 0 #c7c7cc;
				padding-bottom: 24px;
				.title {
					margin: 0;
					font-style: normal;
					font-weight: 600;
					font-size: 40px;
					line-height: 51px;
					color: #000000;
					@media (max-width: 575px) {
						font-size: 30px;
					}
				}
				.txt {
					margin: 16px 0 0;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 160%;
					color: #000000;
				}
				.more {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #000000;
					text-decoration: none;
					margin-top: 24px;
					display: block;
					.ic-arrow-short-rigth {
						margin-right: 8px;
					}
				}
			}
			& > *:last-child {
				box-shadow: unset;
			}
		}
	}
}
</style>