<template>
	<section class="mainBannerSection">
		<HeaderMenu></HeaderMenu>
		<MobHeaderMenu @menuswitch="$emit('menuswitch')"></MobHeaderMenu>
		<transition mode="out-in" name="hm">
			<template v-for="(item, index) in compdata.list">
				<div class="slide" :key="index" v-if="currentSlide == index">
					<!-- currentSlide == index -->
					<div
						class="bgDesctop"
						:style="{ backgroundImage: `url('${path(item.image)}')` }"
					></div>
					<div
						class="bgMobile"
						:style="{ backgroundImage: `url(${path(item.image_mob)})` }"
					></div>
					<div class="mainBanner">
						<div class="txtWrap">
							<h1
								class="caption"
								:style="{ color: item.title_color || '#F4A700' }"
							>
								{{ item.title }}
							</h1>
							<p class="subtext">{{ item.text }}</p>
						</div>
						<div class="botControls">
							<a v-if="item.btn_event == 'on'" :href="$store.getters.formLnk" class="more"
								><img :src="`${require('@/assets/img/hand.svg')}`" alt="123" />{{
									item.btn_name
								}}</a
							>
							<template v-else>
								<a v-if="isExtUrl(item.btn_link || '')" :href="item.btn_link" class="more"
									><img :src="`${require('@/assets/img/hand.svg')}`" alt="" />{{
										item.btn_name
									}}</a
								>
								<router-link v-if="!isExtUrl(item.btn_link || '')" :to="{path: item.btn_link}" class="more"
									><img :src="`${require('@/assets/img/hand.svg')}`" alt="" />{{
										item.btn_name
									}}</router-link
								>
							</template>
							
							<div class="navDots">
								<component
									v-for="(dot, dotIndex) in compdata.list"
									:key="dotIndex"
									:is="index == dotIndex ? 'SvgCircle' : 'Dot'"
									:fillValue="fillValue"
									@click="onCircleClick(dotIndex)"
								></component>
							</div>
						</div>
					</div>
				</div>
			</template>
		</transition>
	</section>
</template>

<script>
import HeaderMenu from "@/components/common/HeaderMenu.vue";
import MobHeaderMenu from "@/components/common/MobHeaderMenu.vue";
import SvgCircle from "@/components/common/SvgCircle.vue";
import Dot from "@/components/common/Dot.vue";
import pauseable from "pauseable";


export default {
	name: "MainBanner",
	props: ["compdata"],
	components: {
		HeaderMenu,
		MobHeaderMenu,
		SvgCircle,
		Dot,
	},
	data() {
		return {
			// bannerData: null,
			currentSlide: 0,
			duration: 10000,
			currentTime: 0,
			timerId: null,
		};
	},
	methods: {
		next() {
			if (this.currentSlide < Object.keys(this.compdata.list).length - 1) this.currentSlide++;
			else this.currentSlide = 0;
		},
		onCircleClick(ind) {
			this.currentSlide = +ind;
			this.timerId.clear();
			this.timerId = pauseable.setInterval(() => {
				this.next();
			}, this.duration);
		},
	},
	computed: {
		fillValue() {
			// 152-77  master-75
			let p = 75 - (this.currentTime * 75) / this.duration + 77;
			return p;
		},
	},
	mounted() {
		this.timerId = pauseable.setInterval(() => {
			this.next();
		}, this.duration);
		setInterval(() => {
			let t = this.timerId.next();
			let tt = t < 0 ? 0 : t;
			this.currentTime = this.duration - tt;
		}, 10);
	},
};
</script>

<style lang="scss">
.hm-enter-active,
.hm-leave-active {
	transition: transform 0.3s, opacity 0.3s;
}
.hm-enter {
	transform: translate(50px, 0);
	opacity: 0;
}
.hm-leave-to {
	transform: translate(-50px, 0);
	opacity: 0;
}
.mainBannerSection {
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: #f3f2f8;
	@media (max-width: 1199px) {
		height: 600px;
	}
	@media (max-width: 1023px) {
		height: 100vh;
	}
	.bgDesctop {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-position: bottom right;
		background-size: cover;
		z-index: 1;
		@media (max-width: 1023px) {
			display: none;
		}
	}
	.bgMobile {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-position: bottom left;
		background-size: cover;
		z-index: 1;
		@media (max-width: 1023px) {
			display: block;
		}
	}
	.slide {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding-top: 116px;
		@media (max-width: 1365px) {
			padding-top: 59px;
		}
	}
	.mainBanner {
		position: relative;
		z-index: 10;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: 80px 140px;
		@media (max-width: 1919px) {
			padding: 55px 110px 70px;
		}
		@media (max-width: 1559px) {
			padding: 32px 48px 48px;
		}
		@media (max-width: 1365px) {
			padding: 32px 48px 48px;
		}
		@media (max-width: 1199px) {
			padding: 32px 48px 48px;
		}
		@media (max-width: 1023px) {
			padding: 48px;
		}
		@media (max-width: 767px) {
			padding: 50px 16px;
		}
		@media (max-width: 425px) {
			padding: 40px 16px 16px;
		}
		.txtWrap {
			.caption {
				font-style: normal;
				font-weight: 600;
				font-size: 110px;
				line-height: 100%;
				color: #f4a700;
				margin: 0;
				width: 66%;
				@media (max-width: 1919px) {
					font-size: 95px;
					width: 70%;
				}
				@media (max-width: 1599px) {
					font-size: 75px;
					width: 60%;
				}
				@media (max-width: 1365px) {
					font-size: 75px;
				}
				@media (max-width: 1199px) {
					font-size: 75px;
					width: 80%;
				}
				@media (max-width: 1023px) {
					font-size: 75px;
					width: 100%;
				}
				@media (max-width: 767px) {
					font-size: 60px;
				}
				@media (max-width: 575px) {
					font-size: 45px;
				}
				@media (max-width: 425px) {
					font-size: 40px;
				}
				@media (max-width: 375px) {
					font-size: 30px;
				}
			}
			.subtext {
				font-style: normal;
				font-weight: 600;
				font-size: 26px;
				line-height: 160%;
				color: #000000;
				margin: 24px 0 0;
				width: 50%;
				@media (max-width: 1919px) {
					font-size: 18px;
				}
				@media (max-width: 1599px) {
					font-size: 18px;
				}
				@media (max-width: 1365px) {
					font-size: 18px;
				}
				@media (max-width: 1199px) {
					font-size: 18px;
				}
				@media (max-width: 1023px) {
					font-size: 18px;
					width: 100%;
				}
				@media (max-width: 767px) {
					font-size: 18px;
				}
				@media (max-width: 425px) {
					font-size: 14px;
				}
			}
		}
		.botControls {
			@media (max-width: 1023px) {
				width: 100%;
				display: flex;
				flex-direction: column;
			}
			.more {
				display: inline-block;
				padding: 20px 90px;
				background: #e10b17;
				border-radius: 1px;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 150.3%;
				color: #ffffff;
				text-decoration: none;
				position: relative;
				&:hover {
					background: #c3141d;
				}
				@media (max-width: 1023px) {
					align-self: flex-end;
				}
				@media (max-width: 425px) {
					padding: 20px 24px 20px 76px;
				}
				img {
					position: absolute;
					top: 50%;
					left: 16px;
					transform: translate(0, -50%);
				}
			}
			.navDots {
				display: flex;
				margin-top: 80px;
				@media (max-width: 1919px) {
					margin-top: 48px;
				}
				@media (max-width: 1599px) {
					margin-top: 40px;
				}
				@media (max-width: 1023px) {
					align-self: center;
				}
			}
		}
	}
}
</style>