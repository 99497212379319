<template>
	<section class="homeSignSection">
		<h2 class="topText">{{compdata.title}}</h2>
		<div class="homeSignBotBlock">
			<img :src="path(compdata.image)" alt="123">
			<div class="g">
				<div class="t1"><span class="ic-curved-arrow"></span>{{compdata.subtitle}}</div>
				<a :href="$store.getters.formLnk"><span class="ic-hand-1"></span>{{compdata.btn_name}}</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SignSection',
	props: ['compdata'],
}
</script>

<style lang="scss">
.homeSignSection{
    background-color: #FCD25D;

    padding: 80px 110px;
    @media (max-width: 1599px) {
        padding: 80px 48px;
    }
    @media (max-width: 1365px) {
        padding: 80px 48px;
    }
    @media (max-width: 1199px) {
        padding: 80px 48px;
    }
    @media (max-width: 1023px) {
        padding: 64px 48px 64px;
    }
    @media (max-width: 575px) {
        padding: 64px 16px 64px;
    }

    .topText{
        font-weight: 600;
        font-size: 86px;
        line-height: 110%;
        color: #fff;
        margin: 0;
        @media (max-width: 1599px) {
            font-size: 65px;
            padding: 0 48px;
        }
        @media (max-width: 1023px) {
            font-size: 55px;
            padding-left: 0;
            padding-right: 0;
        }
        @media (max-width: 767px) {
            font-size: 45px;
        }
        @media (max-width: 575px) {
            font-size: 40px;
        }
        @media (max-width: 575px) {
            font-size: 30px;
        }
        .l{
            text-align: left;
            
        }
        .r{
            text-align: right;
            @media (max-width: 1023px) {
                text-align: left;
            }
        }
    }
    .homeSignBotBlock{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 1190px;
        margin-top: 135px;
        @media (max-width: 1599px) {
            flex-direction: column;
            margin-top: 70px;
        }
        @media (max-width: 1199px) {
        }
        img{
            max-width: 100%;
        }
    }
    .g{
        margin-left: 118px;
        margin-top: 48px;
        @media (max-width: 1599px) {
            margin-left: 0;
            width: 100%;
        }
        .t1{
            font-style: normal;
            font-weight: 600;
            font-size: 34px;
            line-height: 43px;
            color: #000000;
            @media (max-width: 1599px) {
                text-align: center;
            }
            @media (max-width: 1023px) {
                font-size: 24px;
                text-align: left !important;
            }
        }
        a{
            width: 324px;
            height: 64px;
            border-radius: 1px;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150.3%;
            color: #FFFFFF;
            background-color: #E10B17;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            margin-top: 20px;
            transition: 0.8s cubic-bezier(0.76, -0.51, 0.29, 1.6);
            border: none;
            @media (max-width: 1023px) {
                width: 100%;
            }
            @media (max-width: 575px) {
                width: 100%;
            }
            &:hover{
                background-color: #C4141D;
            }
            .ic-hand-1{
                font-size: 32px;
                margin-right: 33px;
            }
            
        }
    }
}
</style>