import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import VueYouTubeEmbed from 'vue-youtube-embed'
import Vuelidate from 'vuelidate'
import WaveSurferVue from "wavesurfer.js-vue";
import SlideUpDown from 'vue-slide-up-down'


import {
    pathUtil
} from '@/utils.js';

Vue.use(VueYouTubeEmbed);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(WaveSurferVue);
Vue.use(require('vue-moment'));
Vue.component('slide-up-down', SlideUpDown)
Vue.config.productionTip = false;
Vue.mixin(pathUtil);

router.beforeEach((to, from, next) => {
    i18n.locale = to.params.locale || 'uk';
    if (to.path.length > 1 && to.path[to.path.length - 1] == '/') {
        next({
            path: to.path.substring(0, to.path.length - 1)
        });
    } else next();
});
router.afterEach((to) => {
	document.body.classList.toggle("noscroll", false);
    setTimeout(() => {
        let data = {
            'event': 'page_view',
            'CityName': document.CityName || '',
            'pageTitle': document.title || '',
            'pagePath': to.fullPath,
            'originalLocation': window.location.origin,
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
        document.CityName = null;
    }, 2000);
    if ('registrationform' in to.query) {
		setTimeout(() => {
			Bus.$emit('showRegForm');
		}, 500);
    }
})

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

let Bus = new Vue();
export {
    Bus
}
