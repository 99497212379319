<template>
	<router-view :key="$route.path" />
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '@/scss/icofont.scss';
body{
	overflow-x: hidden;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.fadefast-enter-active,
.fadefast-leave-active {
	transition: opacity 0.2s;
}
.fadefast-enter,
.fadefast-leave-to {
	opacity: 0;
}

.loadingFiller {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #c4141d;
	border-color: #c4141d transparent #c4141d transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
