<template>
	<div class="modalVideo" @click.stop="$emit('close')">
		<span class="ic-close-rounded" @click="$emit('close')"></span>
		<div class="wrapper" ref="wrapper1">
			<transition name="fade"
				><youtube
					v-if="calculate"
					:video-id="videoId"
					:player-width="playerWidth"
					:player-height="playerHeight"
					ref="ytcomponent"
				></youtube
			></transition>
		</div>
	</div>
</template>

<script>
export default {
	props: ["videoId"],
	components: {},
	data() {
		return {
			side: "w",
			calculate: false,
		};
	},
	methods: {},
	computed: {
		playerWidth() {
			if (this.calculate) return this.$refs.wrapper1.offsetWidth;
			else return null;
		},
		playerHeight() {
			if (this.calculate) return Math.floor((this.playerWidth * 9) / 16);
			else return null;
		},
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.calculate = false;
			this.calculate = true;
		});
		setTimeout(() => {
			this.calculate = true;
		}, 50);
	},
};
</script>

<style lang="scss">
.modalVideo {
	position: fixed;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	.ic-close-rounded {
		width: 46px;
		height: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		color: #fff;
		position: absolute;
		top: 9px;
		right: 9px;
		z-index: 1;
		cursor: pointer;
	}
	.wrapper {
		width: 100%;
		max-width: 900px;
	}
}
</style>