import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        settings: null,
        burgerMenu: null,

        bandaForm: null
    },
    mutations: {
        storeSetings(state, payload) {
            state.settings = payload.data;
        },
        storeBurgerMenu(state, payload) {
            state.burgerMenu = payload;
        },
        setBandaForm(state, payload) {
            state.bandaForm = payload;
        }
    },
    getters: {
        formLnk: state => {
            return state.settings ? state.settings.sing_link : null;
        },
        ftMenu: state => {
            return state.settings ? state.settings.footer_menu : null;
        },
        cpr: state => {
            return state.settings ? state.settings.copyright : null;
        },
        phone: state => {
            if (state.settings) {
                return state.settings.phones.length > 0 ? state.settings.phones[0] : null;
            } else return null
        },
        getMesasngersT: state => {
            let a = [];
            a.push({
                type: 'telegram',
                url: state.settings.telegram
            });
            a.push({
                type: 'viber',
                url: state.settings.viber
            });
            a.push({
                type: 'phone',
                url: `tel:${state.settings.phones[0].number}`
            });
            return a;
        },
        getSocials: state => {
            if (!state.settings) return null;
            let a = [];
            // a.push({
            //     type: 'facebook',
            //     url: state.settings.facebook
            // });
            // a.push({
            //     type: 'instagram',
            //     url: state.settings.instagram
            // });
            a.push({
                type: 'facebook',
                url: state.settings.aside_facebook
            });
            a.push({
                type: 'instagram',
                url: state.settings.aside_instagram
            });
            a.push({
                type: 'telegram',
                url: state.settings.aside_telegram
            });
            a.push({
                type: 'tiktok',
                url: state.settings.aside_tiktok
            });
            return a;
        },
        contactForm: state => {
            if (state.settings)
                return {
                    title: state.settings.form_title,
                    sub: state.settings.form_subtitle,
                };
            else return null;
        },
        donorsPageTitle: state => {
            if (state.settings)
                return state.settings.donors_page_name;
            else return null;
        },
        burgerMenu: state => {
            return state.burgerMenu;
        },
        newsTitle: state => {
            return state.settings ? state.settings.news_title : null;
        },
        getBandaForm: state => state.bandaForm
    },
    actions: {},
    modules: {},
});
