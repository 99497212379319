<template>
	<footer class="ft1">
		<div class="ftContainer">
			<div class="copyright" v-if="$store.getters.cpr" v-html="$store.getters.cpr"></div>
			<div class="links" v-if="$store.getters.ftMenu">
				<router-link v-for="(item, index) in $store.getters.ftMenu" :key="index" :to="{path: item.url}" class="lnk">{{item.name}}</router-link>
			</div>
		</div>
		<div class="createdContainer">
			<a href="https://owlweb.com.ua/" class="createdBy" target="_blank" rel="nofollow">Created by<span class="ic-owlweb"></span></a>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style lang="scss">
.ft1{
    padding: 21px 60px;
    @media (max-width: 1050px) {
        flex-direction: column;
    }
    @media (max-width: 1023px) {
        padding: 24px 48px;
    }
    @media (max-width: 575px) {
        padding: 24px 16px;
    }
    background: #E5E5EA;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.08);

    display: flex;
    @media (max-width: 1200px) {
        flex-direction: column;
        padding: 0;
    }
    .ftContainer{
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1200px) {
            padding: 24px 16px;
            flex-direction: column;
        }
    }
    .createdContainer{
        @media (max-width: 1200px) {
            display: flex;
            justify-content: center;
            padding: 16px 0;
            box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.08);
        }
        .createdBy{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #8E8E93;
            text-decoration: none;
            .ic-owlweb{
                margin-left: 5px;
            }
            &:hover{
                text-decoration: underline;
                color: #000;
            }
        }
    }
    .copyright{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        .gray{
            color: #8E8E93;
        }
    }
    .links{
        @media (max-width: 1050px) {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
        }
        .lnk{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            text-decoration: none;
            margin: 0 12px;
            @media (max-width: 1200px) {
                margin: 4px 0px;
            }
            &:hover{
                text-decoration: underline;
                color: #E10B17;
            }
        }
    }
}
</style>