<template>
	<section class="donationSteps">
		<div class="topText">
			<div class="l">{{compdata.title}}</div>
			<div class="r">{{compdata.title2}}</div>
		</div>
		<div class="stepsWrap">
			<div class="scroller">
				<div class="item" v-for="(item, index) in compdata.list" :key="index">
					<div class="imgWrp">
						<img :src="path(item.image)" :alt="path(item.image)">
					</div>
					<p class="num">{{index}}.</p>
					<p class="caption">{{item.title}}</p>
					<p class="txt">{{item.text}}</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
// import LottieVue from "vue-lottie-light"

// import animationData1 from '@/assets/lottie/lottie1.json'
export default {
	name: 'DonationSteps',
	components: {
		// LottieVue,
	},
	props: ['compdata'],
	data() {
		return {
			// animationData: animationData1,
			// defaultOptions: {animationData: animationData1}
		}
	},
	methods: {
		handleAnimation: function (anim) {
			this.anim = anim;
		},
	},
}
</script>

<style lang="scss">
.donationSteps{
    padding-top: 60px;
    padding-bottom: 80px;
    background: #F8F8F8;
    @media (max-width: 1023px) {
        padding: 64px 0;
    }
    .topText{
        padding: 0 110px;

        font-weight: 600;
        font-size: 86px;
        line-height: 110%;
        color: #AEAEB2;
        @media (max-width: 1599px) {
            font-size: 65px;
            padding: 0 48px;
        }
        @media (max-width: 1023px) {
            font-size: 55px;
        }
        @media (max-width: 767px) {
            font-size: 45px;
        }
        @media (max-width: 575px) {
            padding: 0 16px;
            font-size: 40px;
        }
        @media (max-width: 575px) {
            font-size: 30px;
        }
        .l{
            text-align: left;
            
        }
        .r{
            text-align: right;
            @media (max-width: 1023px) {
                text-align: left;
            }
        }
    }
    .stepsWrap{
        padding: 40px 80px 0;
        @media (max-width: 1599px) {
            padding: 40px 48px 0;
        }
        @media (max-width: 1023px) {
            padding: 40px 0px 0;
        }
        overflow-x: auto;
        overflow-y: hidden;
        .scroller{
            display: flex;
            justify-content: space-between;
            @media (max-width: 1023px) {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
                padding: 0 48px 0px;
            }
        }
        .item{
            width: calc(25% - 30px);
            // margin: 0 20px;
            vertical-align: top;
            @media (max-width: 1023px) {
                display: inline-block;
                flex: 0 0 250px;
                width: 250px;
            }
            & + .item{
                @media (max-width: 1023px) {
                    margin-left: 24px;
                }
            }
            .imgWrp{
                img{
                    display: block;
                    width: 100%;
                }
            }
            .num{
                font-weight: 800;
                font-size: 48px;
                line-height: 100%;
                color: #F49E15;
                margin: 10px 0 0;
            }
            .caption{
                font-weight: 600;
                font-size: 24px;
                line-height: 31px;
                color: #000000;
                margin: 20px 0 0;
                white-space: normal;
            }
            .txt{
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                margin: 20px 0 0;
                white-space: normal;
            }
        }
        .item:nth-child(odd){
            padding-top: 110px;
            @media (max-width: 1023px) {
                padding-top: 0;
            }
        }
    }
}
</style>