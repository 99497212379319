<template>
	<div class="topHeaderMob">
		<div class="leftMenu">
			<div class="mobMenuBtn" @click="$emit('menuswitch')">
				<span class="ic-menu-burger"></span>
			</div>
			<router-link
				:to="{
					name: 'home',
					params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
				}"
				class="logo"
			>
				<img :src="`${require('@/assets/img/logo.svg')}`" alt="" />
			</router-link>
		</div>
		<div class="rightMenu">
			<div class="mobLoginActions"><span class="ic-user"></span></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MobHeaderMenu",
	methods: {},
};
</script>

<style lang="scss">
.topHeaderMob {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	padding: 40px 55px;
	justify-content: space-between;
	@media (max-width: 1365px) {
		display: flex;
		padding: 10px 20px;
		background: rgba(255, 255, 255, 0.85);
	}
	.leftMenu {
		display: flex;
		align-items: center;
		.mobMenuBtn {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #e10b17;
			margin-right: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 13px;
		}
		.logo {
			margin-right: 25px;
		}
	}
	.rightMenu {
		display: flex;
		align-items: center;
		.mobLoginActions {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #f4a700;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			color: #fff;
			// TODO TEMP
			display: none;
		}
	}
}
</style>