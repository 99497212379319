<template>
	<div class="layoutWrp">
		<transition name="fadefast" mode="out-in">
			<div v-if="!loaded" class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
			<div v-if="loaded" :key="'pagecontent'">
				<template v-for="(comp, index) in constructor">
					<component
						v-if="comp.visibility == 1"
						:key="index"
						:is="comp.component"
						:compdata="comp.content"
						@menuswitch="$emit('menuswitch')"
					></component>
				</template>
			</div>
		</transition>
	</div>
</template>

<script>

import MainBanner from "@/components/home/MainBanner.vue";
import DonationSteps from "@/components/home/DonationSteps.vue";
import FullWidthBanner from "@/components/home/FullWidthBanner.vue";
import DonationBenefit from "@/components/home/DonationBenefit.vue";
import HomeVideoAndInfo from "@/components/home/HomeVideoAndInfo.vue";
import PeopleShowcase from "@/components/home/PeopleShowcase.vue";
import SignSection from "@/components/home/SignSection.vue";

export default {
	name: "HomeView",
	components: {
		"main-slider": MainBanner,
		"steps1": DonationSteps,
		"full-image": FullWidthBanner,
		"donor-benefits": DonationBenefit,
		"how-everything-goes": HomeVideoAndInfo,
		"donors-slider": PeopleShowcase,
		"join-us": SignSection,
		"plasmacenters": () => import('@/components/home/ContactMap.vue'),
	},
	data() {
		return {
			loaded: false,
			constructor: null,
		};
	},
	methods: {
		
	},
	created() {
		this.axios.post("/api/get-main-page", { lang: this.$i18n.locale }).then((response) => {
			this.constructor = response.data.data.constructor;
			document.title = response.data.data.translate.meta_title;
			this.loaded = true;
		});
	},
	mounted() {

	},
};
</script>

<style lang="scss">
.layoutWrp{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
</style>
