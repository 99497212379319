<template>
	<div class="dotItem" :class="theme" @click="$emit('click')">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			fill="none"
			viewBox="0 0 30 30"
			class="selectedSlide"
		>
			<circle cx="15" cy="15" r="12" stroke-width="6" class="ldbg"></circle>
			<circle
				cx="15"
				cy="15"
				r="12"
				stroke-width="6"
				class="ld"
				:style="{ strokeDasharray: `${fillValue}px` }"
			></circle>
		</svg>
	</div>
</template>

<script>
export default {
	name: "SvgCircle",
	props: ["fillValue", "theme"],
};
</script>

<style lang="scss">
.dotItem {
	cursor: pointer;
	width: 38px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px 0 0;
	.selectedSlide {
		width: 38px;
		height: 38px;
		.ldbg {
			stroke: #f4a700;
			opacity: 0.38;
			@media (max-width: 1023px) {
				stroke: rgb(255, 255, 255);
			}
		}
		.ld {
			stroke-dasharray: 152;
			stroke-dashoffset: -152;
			transform: rotateZ(-90deg);
			transform-origin: center center;

			stroke: #f4a700;
			@media (max-width: 1023px) {
				stroke: #fff;
			}
		}
	}
	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: #f4a700;
		@media (max-width: 1023px) {
			background-color: #fff;
		}
	}
	&.dark {
		.selectedSlide {
			width: 31px;
			height: 31px;
			.ldbg {
				stroke: #000 !important;
				opacity: 0.32;
				transition: 0.3s ease;
			}
			.ld {
				stroke: #000 !important;
				transition: 0.3s ease;
			}
		}
	}
}
</style>