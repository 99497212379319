import Vue from 'vue';
import VueRouter from 'vue-router';
import MainLayout from '../layouts/MainLayout.vue';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:locale(ru|uk|en)?/brave/thankyou',
        name: 'bandaLandingLayoutThankyou',
        component: () => import( /* webpackChunkName: "banda" */ '@/layouts/bandathankyou.vue'),
    },
    {
        path: '/:locale(ru|uk|en)?/brave/:slug',
        name: 'bandaLandingLayout',
        component: () => import( /* webpackChunkName: "banda" */ '@/layouts/banda.vue'),
    },
    {
        path: '/:locale(ru|uk|en)?/promo/:slug',
        name: 'promoLayout',
        component: () => import( /* webpackChunkName: "promo" */ '@/layouts/promo.vue'),
    },
    {
        path: '/:locale(ru|uk|en)?/donation',
        name: 'DonationForm',
        component: () => import( /* webpackChunkName: "DonationForm" */ '@/layouts/DonationFormLayout.vue'),
    },
    {
        path: '/:locale(ru|uk|en)?/anketa',
        name: 'Anketa',
        component: () => import( /* webpackChunkName: "Anketa" */ '@/layouts/AnketaLayout.vue'),
    },
    {
        path: '/:locale(ru|uk|en)?',
        name: 'homeLayout',
        component: MainLayout,
        children: [{
            name: 'home',
            path: '/',
            component: HomeView,
            // component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView.vue'),
        },
        {
            name: 'plasmacenters',
            path: 'plasmacenters',
            component: () => import( /* webpackChunkName: "PlasmaCentersView" */ '../views/PlasmaCentersView.vue'),
        },
        {
            name: 'plasmacenter',
            path: 'plasmacenter/:slug',
            // component: () => import( /* webpackChunkName: "PlasmaCenterView" */ '../views/PlasmacenterView.vue'),
            component: () => import( /* webpackChunkName: "PostView" */ '../views/PostView.vue'),
        },
        {
            name: 'faq',
            path: 'faq',
            component: () => import( /* webpackChunkName: "FaqView" */ '../views/FaqView.vue'),
        },
        {
            name: 'about',
            path: 'about',
            component: () => import( /* webpackChunkName: "AboutVue" */ '../views/AboutVue.vue'),
        },
        {
            name: 'howtodonor',
            path: 'stati-donorom-suuuper-prosto',
            component: () => import( /* webpackChunkName: "HowToDonor" */ '../views/HowToDonor.vue'),
        },


        {
            name: 'donors',
            path: 'donors',
            component: () => import( /* webpackChunkName: "DonorsView" */ '../views/DonorsView.vue'),
        },
        {
            name: 'donor',
            path: 'donor/:slug',
            component: () => import( /* webpackChunkName: "PostView" */ '../views/PostView.vue'),
        },
        {
            name: 'news',
            path: 'news',
            component: () => import( /* webpackChunkName: "NewsView" */ '../views/NewsView.vue'),
        },
        {
            name: 'article',
            path: 'news/:slug',
            component: () => import( /* webpackChunkName: "PostView" */ '../views/PostView.vue'),
        },

        {
            name: 'post',
            path: ':slug',
            component: () => import( /* webpackChunkName: "PostView" */ '../views/PostView.vue'),
        },

        ],
    },
    {
        path: '*',
        name: 'error404',
        component: () => import( /* webpackChunkName: "Error404" */ '../views/error404.vue'),
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
