import {
	MEDIA_PATH_PREFIX,
	FILE_PATH_PREFIX
} from "@/constants.js";

let pathUtil = {
	props: ['compdata'],
	methods: {
		path(s) {
			if (process.env.NODE_ENV == 'development')
				return `https://biopharmaplasma.com${MEDIA_PATH_PREFIX}${s}`;
			else return MEDIA_PATH_PREFIX + s;
		},
		pathFile(s) {
			if (process.env.NODE_ENV == 'development')
				return `https://biopharmaplasma.com${FILE_PATH_PREFIX}${s}`;
			else return FILE_PATH_PREFIX + s;
		},
		isExtUrl(s) {
			return s.includes('http')
		},
		test() {
			alert(`test ${this.name}`)
		},
		refreshSlider(refname) {
			this.$refs[refname].resize();
		},

		getPromo() {
			debugger
			let num = this.$route.path.split('-')[1];
			return num;
		},
		getDonationName(int) {
			if (int == 0) {
				return `${int} ${this.$t("donations.1")}`
			}
			if (int < 5) {
				return `${int} ${this.$t("donations.2")}`

			} else {
				return `${int} ${this.$t("donations.3")}`
			}
		},

		TGMPush(arg) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'page_view',
				'CityName': arg.city || '',
				'pageTitle': arg.title || '',
				'pagePath': this.$route.fullPath,
				'originalLocation': window.location.origin,
			});
		},
		// onPhoneChange(e) {
		//     this[e.name] = e.value;
		//     this.phoneLength = e.length;
		// },
		// onFieldChange(e) {
		//     this[e.name] = e.value;
		// },
	},
}

export {
	pathUtil
}


export let rtParams = {
	ruLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = 'ru';
		return p;
	},
	uaLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = null;
		return p;
	},
	enLocaleParam() {
		let p = {};
		for (const [key, value] of Object.entries(this.$route.params)) {
			p[key] = value;
		}
		p.locale = 'en';
		return p;
	},
};
