<template>
	<div class="dotItem" @click="$emit('click')">
		<div class="dot"></div>
	</div>
</template>

<script>
export default {
	name: 'Dot'
}
</script>

<style lang="scss">
.dotItem {
	cursor: pointer;
	width: 38px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px 0 0;
	.selectedSlide {
		width: 38px;
		height: 38px;
		.ldbg {
			stroke: #f4a700;
			opacity: 0.38;
			@media (max-width: 1023px) {
				stroke: rgb(255, 255, 255);
			}
		}
		.ld {
			stroke-dasharray: 152;
			stroke-dashoffset: -152;
			transform: rotateZ(-90deg);
			transform-origin: center center;

			stroke: #f4a700;
			@media (max-width: 1023px) {
				stroke: #fff;
			}
		}
	}
	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: #f4a700;
		@media (max-width: 1023px) {
			background-color: #fff;
		}
	}
}
</style>