<template>
	<div class="mainWrap">
		<transition name="menuAppear">
			<template v-if="isMenuOpened">
				<DesctopMenu
					v-if="diveseType == 'desctop'"
					@onmenustatechange="changeMenuState"
					@close="onClose"
				></DesctopMenu>
				<MobileMenu
					v-if="diveseType == 'mob'"
					@onmenustatechange="changeMenuState"
					@close="onClose"
				></MobileMenu>
			</template>
		</transition>
		<SideBar @onmenustatechange="changeMenuState"></SideBar>
		<transition name="fadefast" mode="out-in">
			<router-view @menuswitch="changeMenuState" :key="$route.fullPath" />
		</transition>
		<Footer></Footer>
		<ContactBtn
			@click="changeWidgetState"
			:state="isContWidgetOpened"
		></ContactBtn>
		<transition name="rightAppear">
			<ContactWidget
				v-if="isContWidgetOpened"
				key="w1"
				@opentellwidget="changeTelWidget"
				@close="isContWidgetOpened = false"
			></ContactWidget>
			<ContactPhoneFormBtn
				v-if="isTelWidgetOpened"
				key="w2"
				@formclose="isTelWidgetOpened = false"
			></ContactPhoneFormBtn>
		</transition>

		<caution v-if="isCaution" @close="onCautionClose"></caution>
	</div>
</template>

<script>
import { Bus } from "@/main.js";

import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/SideBar.vue";
import ContactBtn from "@/components/common/ContactBtn.vue";
import ContactWidget from "@/components/common/ContactWidget.vue";
import Caution from "../components/common/Caution.vue";
export default {
	name: "MainLayout",
	components: {
		DesctopMenu: () => import("@/components/common/DesctopMenu.vue"),
		MobileMenu: () => import("@/components/common/MobileMenu.vue"),
		SideBar,
		ContactBtn,
		Footer,
		ContactPhoneFormBtn: () =>
			import("@/components/common/ContactPhoneFormBtn.vue"),
		ContactWidget,
		Caution,
	},
	data() {
		return {
			diveseType: null,
			isMenuOpened: false,
			isContWidgetOpened: false,
			isTelWidgetOpened: false,
			hideRouterView: false,

			isCaution: false,

			// siteMenu: null,
		};
	},
	methods: {
		setDeviceType() {
			if (window.innerWidth > 768) {
				this.diveseType = "desctop";
			} else {
				this.diveseType = "mob";
			}
		},
		changeMenuState() {
			this.isMenuOpened = !this.isMenuOpened;
		},
		changeWidgetState() {
			this.isContWidgetOpened = !this.isContWidgetOpened;
		},
		changeTelWidget() {
			this.isContWidgetOpened = false;
			this.isTelWidgetOpened = !this.isTelWidgetOpened;
		},
		getSetings() {
			this.axios
				.post("/api/settings/all", {
					lang: this.$i18n.locale,
				})
				.then((response) => {
					this.$store.commit("storeSetings", { data: response.data.data });
				});
		},
		onClose() {
			this.isMenuOpened = false;
			document.body.classList.toggle("noscroll", false);
		},
		onCautionClose() {
			this.isCaution = false;
			window.localStorage.setItem('caution', true);
		},
	},
	mounted() {
		this.setDeviceType();
		window.addEventListener("resize", () => {
			this.setDeviceType();
		});
		this.axios
			.post("/api/menu/get-by-ids", {
				ids: [164],
				lang: this.$i18n.locale,
			})
			.then((response) => {
				// this.siteMenu = response.data.data[164].items;
				this.$store.commit("storeBurgerMenu", response.data.data[164].items);
				this.getSetings();
			});
		setTimeout(() => {
			Bus.$on("langChange", this.getSetings);
		}, 200);
		
		if (window.localStorage.getItem("caution") == 'true') this.isCaution = false;
		else this.isCaution = true;
	},
	watch: {
		isMenuOpened(newValue) {
			if (newValue) {
				document.body.classList.toggle("noscroll", true);
			} else {
				document.body.classList.toggle("noscroll", false);
			}
		},
	},
};
</script>

<style lang="scss">
@import "../scss/mainSiteStyles.scss";
.menuAppear-enter-active,
.menuAppear-leave-active {
	transition: transform 0.5s;
}
.menuAppear-enter,
.menuAppear-leave-to {
	transform: translate(-100%, 0);
}
.rightAppear-enter-active,
.rightAppear-leave-active {
	transition: 0.3s ease;
}
.rightAppear-enter,
.rightAppear-leave-to {
	transform: translate(100%, -50%);
}
.mainWrap {
	// background-color: red;
	padding-left: 192px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	@media (max-width: 1600px) {
		padding-left: 155px;
	}
	@media (max-width: 1365px) {
		padding-left: 0;
	}
}
.noscroll {
	overflow-y: hidden;
}
</style>
