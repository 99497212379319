<template>
	<section class="donationBenefit">
		<div class="topText">{{ compdata.title }}</div>
		<div class="twoCol twoCol1">
			<div class="l">
				<img :src="`${path(compdata.image)}`" :alt="path(compdata.image)" />
			</div>
			<div class="r">
				<div class="item" v-for="(item, index) in compdata.list" :key="index">
					<div v-if="!item.url" class="caption">{{ item.title }}</div>
					<router-link v-else class="caption" :to="{ path: item.url }">{{
						item.title
					}}</router-link>

					<div class="txt" v-html="item.text"></div>
					<router-link v-if="item.url" class="more" :to="{ path: item.url }"
						><span class="ic-arrow-short-rigth"></span>
						{{ $t("details") }}</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "DonationBenefit",
	props: ["compdata"],
};
</script>

<style lang="scss">
.donationBenefit {
	padding: 60px 110px;
	background: #f2f2f7;
	@media (max-width: 1599px) {
		padding: 60px 48px;
	}
	@media (max-width: 1365px) {
		padding: 60px 48px;
	}
	@media (max-width: 1199px) {
		padding: 60px 48px;
	}
	@media (max-width: 1023px) {
		padding: 64px 48px;
	}
	@media (max-width: 575px) {
		padding: 64px 16px;
	}
	.topText {
		// padding: 0 110px;

		font-weight: 600;
		font-size: 86px;
		line-height: 110%;
		color: #aeaeb2;
		text-align: left;
		@media (max-width: 1599px) {
			font-size: 65px;
			text-align: left;
		}
		@media (max-width: 1023px) {
			font-size: 55px;
		}
		@media (max-width: 767px) {
			font-size: 45px;
		}
		@media (max-width: 574px) {
			font-size: 40px;
		}
		@media (max-width: 375px) {
			font-size: 30px;
		}
	}
	.twoCol {
		margin-top: 40px;
		.l {
			img {
				width: 100%;
				max-width: 400px;
				@media (max-width: 1023px) {
					display: block;
					margin: auto;
				}
			}
		}
		.r {
			.item {
				width: calc(50% - 30px);
				padding-bottom: 24px;
				box-shadow: 0 1px 0 0 #c7c7cc;
				margin-bottom: 32px;
				@media (max-width: 1023px) {
					width: 100%;
				}
				.caption {
					font-weight: 600;
					font-size: 24px;
					line-height: 31px;
					color: #000000;
					text-decoration: none;
					@media (max-width: 575px) {
						font-size: 30px;
					}
				}
				.txt {
					margin-top: 16px;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #000000;
				}
				.more {
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					color: #000000;
					text-decoration: none;
					margin: 16px 0 0;
					display: block;
					.ic-arrow-short-rigth {
						font-size: 15px;
						margin-right: 8px;
					}
				}
				& {
					.caption,
					.txt,
					.more {
						transition: 0.3s ease;
					}
				}
				&:hover {
					.caption,
					.more {
						color: #f39526 !important;
					}
				}
			}
		}
	}
}
</style>