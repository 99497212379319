<template>
	<div class="topHeader">
		<div class="leftMenu">
			<router-link
				:to="{
					name: 'home',
					params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
				}"
				class="logo"
			>
				<img :src="`${require('@/assets/img/logo.svg')}`" alt="" />
			</router-link>
			<template v-for="(item, index) in menu">
				<router-link
					v-if="!item.children"
					:key="index"
					:to="{ path: item.url }"
					class="item"
					>{{ item.name }}</router-link
				>
				<span class="item" v-else :key="index">
					<router-link :to="{ path: item.url }">{{ item.name }}</router-link>
					<div class="dropDownHover">
						<router-link
							v-for="(subItem, index) in item.children"
							:key="index"
							:to="{ path: subItem.url }"
							>{{ subItem.name }}</router-link
						>
					</div>
				</span>
			</template>
		</div>
		<div class="rightMenu">
			<a
				v-if="$store.getters.phone"
				:href="`tel:${$store.getters.phone.number}`"
				class="phoneLnk"
				>{{ $store.getters.phone.number }}</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "HeaderMenu",
	data() {
		return {
			menu: null,
		};
	},
	methods: {},
	created() {
		this.axios
			.post("/api/menu/get-by-ids", {
				ids: [95],
				lang: this.$i18n.locale,
			})
			.then((response) => {
				this.menu = response.data.data["95"].items;
			});
	},
};
</script>

<style lang="scss">
.topHeader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 50;
	padding: 30px 55px;
	display: flex;
	justify-content: space-between;
	@media (max-width: 1365px) {
		display: none;

		padding: 10px 20px;
		background: rgba(255, 255, 255, 0.85);
	}
	.leftMenu {
		display: flex;
		align-items: center;
		.logo {
			margin-right: 25px;
		}
		.dropDownHover {
			display: none;
			position: absolute;
			z-index: 10;
			left: 16px;
			top: calc(0% + 100%);
			background-color: #fff;
			box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
			a {
				display: flex;
				justify-content: space-between;
				align-items: center;
				min-width: 280px;
				min-height: 54px;
				text-decoration: none;
				padding: 0 16px;
				font-style: normal;
				font-weight: 600;
				font-size: 15px;
				line-height: 19px;
				letter-spacing: -0.03em;
				color: #000000;
				.ic-arrow-short-rigth {
					opacity: 0;
					transition: 0.3s ease;
					margin-right: 5px;
				}
			}
			a:hover {
				.ic-arrow-short-rigth {
					opacity: 1;
					transform: translate(10px, 0);
				}
			}
			.mainLnk {
				background-color: #f4a700;
				color: #fff;
				.ic-arrow-short-rigth {
					opacity: 1;
					transform: translate(10px, 0);
				}
			}
		}
		.item {
			padding: 18px 16px 18px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #000000;
			text-decoration: none;
			transition: 0.3 ease;
			position: relative;
			& > a {
				transition: 0.3 ease;
				color: inherit;
				text-decoration: none;
			}
			@media (max-width: 1365px) {
				display: none;
			}
			&:hover {
				color: #f4a700;
			}
		}
		.item:hover .dropDownHover {
			display: block;
		}
	}
	.rightMenu {
		display: flex;
		align-items: center;
		.phoneLnk {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: -0.065em;
			text-transform: uppercase;
			color: #000000;
			text-decoration: none;
			&:hover {
				color: #e10b17;
			}
		}
		.comboWrap {
			display: flex;
			@media (max-width: 1365px) {
				display: none;
			}
		}
		.combot1 {
			padding: 8px 16px;
			.value {
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 100%;
				letter-spacing: -0.065em;
				text-transform: uppercase;
				color: #000000;
				&:hover {
					color: #e10b17;
				}
				.ic-triangle-down {
					font-size: 6px;
					vertical-align: middle;
					margin-left: 10px;
				}
			}
		}
	}
}
</style>