<template>
	<div class="caution">
		<div class="wrpClose"><span class="ic-close-rounded" @click="onClick"></span></div>
		<img class="desc" :src="`${imgSrc[0]}`" alt="caution" />
		<img class="mob" :src="`${imgSrc[1]}`" alt="caution" />
	</div>
</template>

<script>
export default {
	name: "CautionMessage",
	computed: {
		imgSrc() {
			switch (this.$i18n.locale) {
				case "en":
					return [
						require("@/assets/img/cautionEn.svg"),
						require("@/assets/img/cautionEnMob.svg"),
					];
				case "uk":
					return [
						require("@/assets/img/cautionUk.svg"),
						require("@/assets/img/cautionUkMob.svg"),
					];
				case "ru":
					return [
						require("@/assets/img/cautionRu.svg"),
						require("@/assets/img/cautionRuMob.svg"),
					];
				default:
					return [
						require("@/assets/img/cautionUk.svg"),
						require("@/assets/img/cautionUkMob.svg"),
					];
			}
		},
	},
	methods: {
		onClick() {
			this.$emit('close');
		}
	},
};
</script>

<style lang="scss" scoped>
.caution {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: #e9ebf4;
	padding: 0px 0 30px;
	.wrpClose {
		display: flex;
		justify-content: flex-end;
		margin: 15px;
		.ic-close-rounded {
			font-size: 19px;
			color: #e1251b;
			cursor: pointer;
		}
	}
	.desc,
	.mob {
		display: block;
		margin: 0 auto;
		// height: 15vh;
		width: calc(100% - 40px);
	}
	.desc {
		@media (max-width: 1070px) {
			display: none;
		}
	}
	.mob {
		display: none;
		@media (max-width: 1070px) {
			display: block;
		}
	}
}
</style>