<template>
	<section class="peopleShowcase">
		<div class="twoCol1">
			<div class="l">
				<h2 class="caption">{{ compdata.title }}</h2>
				<router-link
					:to="{
						name: 'donors',
						params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
					}"
					class="more"
					>{{ compdata.btn_text }}</router-link
				>
			</div>
			<div class="r">
				<flickity ref="flickity" :options="sliderOptions" class="peoplesWrap">
					<router-link
						v-for="(item, index) in compdata.list"
						:key="index"
						:to="{ path: item.url }"
						class="item"
					>
						<div class="imgWrap">
							<img
								class="pImg"
								:src="`${path(item.image)}`"
								alt=""
								@load="refreshSlider('flickity')"
							/>
							<div class="sub">
								<span class="bp">
									<img :src="`${require('@/assets/img/b.svg')}`" alt="b" />
								</span>
								<span class="donations">
									<img :src="`${require('@/assets/img/plumb.svg')}`" alt="" />
									<span>{{ item.donations }}</span>
								</span>
							</div>
						</div>
						<div>
							<p class="name">{{ item.title }}</p>
							<p class="city">{{ item.city }}</p>
						</div>
					</router-link>
				</flickity>
				<div class="nav">
					<span class="ic-arrow-long-left" @click="onPrev"></span>
					<img :src="`${require('@/assets/img/handCup.svg')}`" alt="123" />
					<span class="ic-arrow-long-rigth" @click="onNext"></span>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Flickity from "vue-flickity";

export default {
	name: "PeopleShowcase",
	props: ["compdata"],
	components: {
		Flickity,
	},
	data() {
		return {
			sliderOptions: {
				cellAlign: "left",
				contain: true,
				pageDots: false,
				prevNextButtons: false,
				imagesLoaded: true,
			},
		};
	},
	methods: {
		onNext() {
			this.$refs.flickity.next();
		},
		onPrev() {
			this.$refs.flickity.previous();
		},
	},
	mounted() {
		let w = this.$refs.flickity.$el.querySelector(".pImg").offsetWidth;
		let h = Math.floor((w * 15) / 9);
		this.$refs.flickity.$el.querySelectorAll(".pImg").forEach((item) => {
			item.style.height = `${h}px`;
		});
		this.$refs.flickity.resize();
		window.addEventListener("resize", () => {
			let w = this.$refs.flickity.$el.querySelector(".pImg").offsetWidth;
			let h = Math.floor((w * 15) / 9);
			this.$refs.flickity.$el.querySelectorAll(".pImg").forEach((item) => {
				item.style.height = `${h}px`;
			});
		});
	},
};
</script>

<style lang="scss">
.peopleShowcase {
	padding: 60px 0 60px 110px;
	overflow: hidden;
	@media (max-width: 1599px) {
		padding: 60px 0 60px 48px;
	}
	@media (max-width: 1365px) {
		padding: 60px 0 60px 48px;
	}
	@media (max-width: 1199px) {
		padding: 60px 0 150px 48px;
	}
	@media (max-width: 1023px) {
		padding: 64px 0 64px 48px;
	}
	@media (max-width: 575px) {
		padding: 64px 0 64px 16px;
	}

	background: #fcd25d;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: #f8f8f8;
		border-bottom-left-radius: 193px;
		z-index: 1;
		@media (max-width: 1365px) {
			border-bottom-left-radius: 160px;
		}
		@media (max-width: 1023px) {
			border-radius: 0;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}

	.twoCol1 {
		.l {
			.caption {
				font-style: normal;
				font-weight: 600;
				font-size: 71px;
				line-height: 110%;
				color: #f4b147;
				margin-right: 118px;
				@media (max-width: 1599px) {
					font-size: 65px;
					padding: 0 48px;
				}
				@media (max-width: 1023px) {
					font-size: 55px;
					margin-right: 48px;
					margin-top: 0;
					padding: 0;
				}
				@media (max-width: 767px) {
					font-size: 45px;
				}
				@media (max-width: 575px) {
					font-size: 40px;
					margin-right: 16px;
				}
				@media (max-width: 575px) {
					font-size: 30px;
				}
			}
			.more {
				display: block;
				border: 1px solid #8e8e93;
				padding: 16px;
				margin-top: 16px;
				text-decoration: none;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #000000;
				transition: 0.3s ease;
				text-align: center;
				margin-right: 118px;
				box-sizing: border-box;
				&:hover {
					color: #fff;
					background: #e10b17;
					border-color: #e10b17;
				}
				@media (max-width: 1023px) {
					margin-right: 48px;
				}
				@media (max-width: 575px) {
					margin-right: 16px;
				}
			}
		}
		.r {
			flex: 0 0 778px;
			@media (max-width: 1199px) {
				flex: 0 0 610px;
				margin-top: 32px;
			}
			@media (max-width: 1023px) {
				flex: unset;
				margin-top: 32px;
			}

			.peoplesWrap {
				width: 100%;
			}
			.item {
				text-decoration: none;
				margin-right: 8px;
				margin-left: 8px;
				width: 300px;
				@media (max-width: 1599px) {
					width: 254px;
				}
				@media (max-width: 1365px) {
					width: 224px;
				}
				@media (max-width: 1023px) {
					width: 210px;
				}
				.imgWrap {
					position: relative;
					overflow: hidden;
					.pImg {
						// width: 300px;
						// height: 520px;
						width: 100%;
						object-fit: cover;
						display: block;
						transition: 0.9s cubic-bezier(0, 0.02, 0, 0.98);
						// @media (max-width: 1599px) {
						// 	width: 254px;
						// 	height: 440px;
						// }
						// @media (max-width: 1365px) {
						// 	width: 224px;
						// 	height: 389px;
						// }
						// @media (max-width: 1023px) {
						// 	width: 210px;
						// 	height: 365px;
						// }
					}
					.sub {
						position: absolute;
						bottom: 8px;
						right: 8px;
						display: flex;
						& > * + * {
							margin-left: 7px;
						}
						.bp {
							display: block;
							width: 46px;
							height: 46px;
							border-radius: 50px;
							background-color: #e10b17;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.donations {
							height: 46px;
							display: flex;
							align-items: center;
							background-color: #fff;
							border-radius: 50px;
							border-top-right-radius: 0;
							padding: 0 16px;
							text-decoration: none;
							img {
								margin-right: 12px;
							}
							span {
								font-style: normal;
								font-weight: 600;
								font-size: 13px;
								line-height: 17px;
								color: #000000;
							}
						}
					}
				}
				.name {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 23px;
					color: #000000;
					margin: 10px 0 0;
				}
				.city {
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					color: #000000;
					margin: 13px 0 0;
				}
				&:hover {
					@media (min-width: 768px) {
						.imgWrap .pImg {
							transform: scale(1.1);
						}
					}
				}
			}
			.nav {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 32px 0 0px;
				width: 100%;
				img {
					margin: 0 10px;
				}
				.ic-arrow-long-left,
				.ic-arrow-long-rigth {
					font-size: 15px;
					cursor: pointer;
				}
			}
		}
	}
}
</style>